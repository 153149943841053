
/* Verify.js */
.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* contribute.js */
#form_contribute {
    max-width: 100%;
    margin: 0 ;
    color: #3f3a27;
    padding: 0.5em;
}

#form_contribute > h5 {
    margin-bottom: 15px;
}

#form_contribute > h6 {
    margin-top: 10px;
    margin-left: 10px;
}

#form_contribute > div {
    display: flex;
    align-items: center
}

#form_contribute > div#smallspace {
    margin-top: 0.3em; 
}

#form_contribute > text#indentmore {
    margin-left: 20px; 
}

#form_contribute > text.smallletter {
    font-size: 12px;  
}

#form_contribute > div > text {
    margin-left: 20px; 
}

#form_contribute > div > label {
    margin-left: 20px;
    width: 20%;
    text-align: left;
    margin-top: 0.3em;  
    margin-bottom: 0.3em; 
    font-size: 16px;   
}

#form_contribute > div > input[type=text] {
    margin-left: 20px;
    height: 30px;
    width: 60%;
    margin-top: 0.3em;  
    margin-bottom: 0.3em; 
    font-size: 16px;  
}

#form_contribute > div > textarea{
    margin-left: 20px;
    width: 60%;
    margin-top: 0.3em;  
    margin-bottom: 0.3em; 
    font-size: 16px;  
}

#form_contribute > div > select {
    margin-left: 20px;
    height: 28px;
    width: 60%;
    margin-top: 0.3em; 
    margin-bottom: 0.3em; 
}


/* VerifyPage.js */
div.topPane > text{
    font-size: 16px; 
}

div.topPane > div{
    font-size: 14px; 
}

.splitScreen {
  height: 100vh;
  display: grid;
  grid-template-columns: 3fr 6fr 4fr;
  grid-template-rows: 1fr;
}

.topPane {
    grid-column: 1;
    grid-row: 1;
    padding: 20px;
    overflow-y: scroll; 
}

.middlePane {
    grid-column: 2;
    grid-row: 1;
    padding: 20px;
    overflow-y: scroll; 
}

.bottomPane {
    grid-column: 3;
    grid-row: 1;
    padding: 20px;
    overflow-y: scroll; 
}

#form_verify > div > div {
    display: flex;
    align-items: center;
    font-size: 12px;
}

#form_verify > div > h6 {
    margin-top: 10px;
}

#form_verify > div > div> label {
    margin-left: 20px;
    width: 20%;
    text-align: left;
    margin-top: 0.3em;  
    margin-bottom: 0.3em;    
}

#form_verify > div > div > input[type=text] {
    margin-left: 20px;
    height: 30px;
    width: 70%;
    margin-top: 0.3em;  
    margin-bottom: 0.3em; 
}

#form_verify > div > div > select {
    margin-left: 20px;
    height: 28px;
    width: 70%;
    margin-top: 0.3em; 
    margin-bottom: 0.3em; 
}

#form_verify > div > div > text {
    margin-left: 20px;
    height: 28px;
    width: 70%;
    margin-top: 0.3em; 
    margin-bottom: 0.3em; 
}

#form_verify > div > div#textonleft {
    margin-left: 20px;
    height: 28px;
    width: 90%;
    margin-top: 0.3em; 
    margin-bottom: 0.3em; 
}

#form_verify > div > div#textonleft > select {
    margin-left: 20px;
    height: 28px;
    width: 100%;
    margin-top: 0.3em; 
    margin-bottom: 0.3em; 
}

#form_verify > div > div > textarea{
    margin-left: 20px;
    width: 70%;
    margin-top: 0.3em;  
    margin-bottom: 0.3em; 
}


#resultState > div {
    display: flex;
    align-items: center;
    font-size: 12px;
}

#resultState > div > label{
    margin-left: 10px;
    width: 30%;
    margin-top: 12px;  
    margin-bottom: 12px; 
}

#resultState > div > text{
    margin-left: 10px;
    width: 60%;
    margin-top: 12px;  
    margin-bottom: 12px; 
    color: green;
}
