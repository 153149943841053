body {
    padding: 0;
    margin: 0;
}

.video {
  /* override other styles to make responsive */
  width: 100%    !important;
  height: 500px  !important;
  margin-bottom:30px;
}

.bulletpoint {
    color: #19C3FB;
}
.wideDiv {
	display:grid;
	 grid-template-columns: 1fr 1fr;
}
.contain {
	height:100vh;
	width:100vw;
}

.button-cover
{

    height: 60px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 10px 20px -12px grey;
    border: .05px solid #D3D3D3;
    border-radius: 4px;
}

.resourceTable{
	padding:10px;
	width:40vw;
	height: 91vh; 
	margin-top:9vh;
	overflow-y:scroll;
}
.resourceMap{
	width: 60vw;
	height: 91vh;
	margin-top:9vh;
}
.dot {
  background: #ffffff;
  font-size:9px;
  border: 0.1875em solid #0F1C3F;
  border-radius: 50%;
  box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);
  height: 5em;
  width: 5em;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}


.label {

	align-items:right;
	margin-top:3px;
  cursor: pointer;
  color: #394a56;
  margin-bottom:0px;
}

.label-text {
  color:black;
  margin-top:5px;
  text-shadow: white 0px 0px 10px;
}

.toggle {
  float:right;
  isolation: isolate;
  position: relative;
  height: 30px;
  width: 60px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow:
    -8px -4px 8px 0px #ffffff,
    8px 4px 12px 0px #d1d9e6,
    4px 4px 4px 0px #d1d9e6 inset,
    -4px -4px 4px 0px #ffffff inset;
}

.toggle-state {
  display: none;
}

.indicator {
  height: 100%;
  width: 200%;
  background: #999999;
  border-radius: 15px;
  transform: translate3d(-75%, 0, 0);
  transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
  box-shadow:
    -8px -4px 8px 0px #ffffff,
    8px 4px 12px 0px #d1d9e6;
}

.toggle-state:checked ~ .indicator {
  transform: translate3d(25%, 0, 0);
}

.mapToggle{
    cursor: pointer;
    text-align: center;
    display: grid;
    padding: 0.25rem 0.75rem;
    font-size: .7rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 0rem 0.25rem 0.25rem 0rem;
}

.toggles{
    display:flex;
}

.toggles-margin-top{
  display: flex;
  margin-top: .5rem;
}

.toggles > div > img {
  margin: auto;
}
.cardToggle{
    cursor: pointer;
    text-align: center;
    display: grid;
    padding: 0.25rem 0.75rem;
    font-size: .7rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 0.25rem 0rem 0rem 0.25rem;
}

.printToggle{
    cursor: pointer;
    text-align: center;
    margin-left: 10px;
    display: grid;
    padding: 0.25rem 0.75rem;
    font-size: .7rem;
    line-height: 1;
    border: 1px solid rgba(0,0,0,.1);
    border-radius: 0.25rem;

}

.mapToggle > img{
  margin: auto;
}
.cardToggle > img {
  margin: auto;
}
.printToggle > img{
  margin: auto;
}

.noDecorationLink a {
    color: black;
}
.noDecorationLink a:link { text-decoration: none; }

.noDecorationLink a:visited { text-decoration: none; }
.noDecorationLink a:hover { text-decoration: none; 
color: black}
.noDecorationLink a:active { text-decoration: none; }

.on{
    background-color: #D2ECFF;
    color: #69A7D3;
    border-color: #69A7D3;
}

.on img{
  filter: invert(63%) sepia(67%) saturate(302%) hue-rotate(163deg) brightness(87%) contrast(88%);
}

.rw-select{
    visibility: hidden;
}

.hiddenWhenUnder1050{
    padding-left:.5rem;
    padding-right:.5rem;
}

.advanceFilters{
  padding-left: .5rem;
  padding-right: .5rem;
  fontSize:13px;
  marginTop:15px
}


.clearFilters{
color: #007bff;
float: left;
margin-left: 5px;
cursor: pointer;
padding: 2px 10px 2px 10px;
border: .05px solid #007bff;
border-radius: 4px;
font-size: 12px;
}

.top-filter{
background-color: #69A7D3;

filter: drop-shadow(0px -4px 15px rgba(93, 97, 99, 0.7));
}

.top-filter > div > div > .nav-link{
  color: white !important;
}

.top-filter > span {
  color: white !important;
}


.navbar-toggler-icon{
      background-image: url(./menu.svg) !important;
}

.custom-navbar{
      filter: drop-shadow(0px -4px 15px rgba(93, 97, 99, 0.5));
}

.contact-container{
  text-align: center;
  height: 91vh;
  margin-top:9vh;
  padding:30px;
  padding-top:20px;
  font-size: 14px;

}

.row {
  margin-bottom: 2em;
}

.data-collection-form-container{
  text-align: left;
}
.contact-container h1 {
    font-size: 32px;
    color: #69A7D3;
    margin-bottom: .5em;
}

.contact-container h3 {
  font-size: 24px;
}

.contact-container h5 {
      margin-top: .5em;
  font-size: 18px;
      color: #69A7D3;
    margin-bottom: .5em;
}


.contact-container a {
  color: #1A8FE3;
}

.contribute-link-div {
  color: #1A8FE3;
  display: inline-grid;
}

  .logo {
    margin-right: 1em;
  }

  .popover-body{
    display: flex;
  }

  .popover-dark{
    width: 15em;
    background-color: #474D66 !important; 
    border-radius: 10px !important;
  }
  .arrow::after{
    border-top-color: #474D66 !important; 
    bottom: 2px;
  }
  .popover-body{
    color: #fff !important;
  }

  .arrow{
    background-color: #fff !important; 
  }

  .closingX{
    margin-top: 3px;
    font-size: 18px;
  }
@media (min-width: 768px){


  .contact-container{
    width: 980px;
    margin-right: auto;
    margin-left:  auto;
  }


  .rw-multiselect{
    margin-right: 2px;
  }
  .fixed-bottom {
    display: block;
      flex-flow: custom;
      justify-content: flex-start;

  }

  .top-bar{
    display: inline-flex;
    margin-bottom: 5px;
    width: 100%;
  }

  .lower-bar{
    display: flex;
    justify-content: flex-end;
  }
  .search-results{
    margin-left: 12px;
    margin-right: 10px;
  }

  .custom-navbar{
    display: block !important;
  }

}
@media only screen and (max-width: 1050px) {
.hiddenWhenUnder1050{
  visibility:hidden;
  padding: 0px;
  height:  0px;
}
}


@media only screen and (max-width: 768px) {


  .print-icon{
    margin-left: 10px;
    width: 17px;
    height: 17px;
  }

  .printToggle{
    position: absolute;
    top: -50px;
    right: 15px;
    background-color: #f8f9fa;
  }
  .navbar-toggler{

  margin-left: auto;
  order: 2;
  }

  .filter-toggle{
    margin-left: 0;
    order: 0;
  }
  .lower-bar{
    width: 100%;
  }

  .lower-bar-close{
    width: auto;
  }
  .resourceTable{
  	padding:10px;
  	width:100vw;
  	height: 91vh; 
  	margin-top:9vh;
  	overflow-y:scroll;
  }
  .resourceMap{
  	width: 100vw;
  	height: 91vh;
  	margin-top:9vh;
  }
  .dropdown-menu.show{
      max-height: 40vh;
      overflow: -moz-scrollbars-vertical; 
      overflow-y: scroll;
  }

  #basic-nav-dropdown {
    padding: 10px 0px 10px 0px;
  }

  .hiddenWhenNotCollapse {
    font-size: .9rem;
    margin-left: 5px;
    margin-right: 10px;

  }

  .advanceFilters {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0px;
  }

  .filter-collapsed{
    font-size: .9rem;
    display: flex;
  }

  .filter-words{
    font-size: .9rem;
     margin: auto;
     padding-right: 10px;

  }

  .filter-toggle{
    display: flex;
  }



}
